.add-ons {
  margin: 20px 0;
  padding: 1px;
  border-radius: 10px;
}

.add-ons h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.add-ons-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-on-container {
  display: flex;
  width: calc(100% - 100px);
}

.add-on-item {
  padding: 20px;
  border: 1px solid #e0e0e0;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 8px;
  min-width: calc(50% - 10px);
  flex: 0 0 calc(50% - 10px);
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.add-on-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.add-on-info {
  display: flex;
  align-items: flex-start;
}

.info-icon {
  margin-right: 15px;
  font-size: 20px;
  color: #4267B2;
}

.add-on-info h3 {
  margin: 0 0 10px;
  color: #333;
  font-size: 18px;
}

.add-on-info p {
  margin: 0 0 10px;
  color: #666;
  font-size: 14px;
}

.price {
  font-weight: bold;
  color: #4267B2;
  font-size: 16px;
}

.add-button {
  background-color: #4267B2;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.add-button:hover {
  background-color: #365899;
}

.scroll-button {
  background-color: #4267B2;
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.scroll-button:hover {
  background-color: #365899;
}

.scroll-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .add-on-item {
    min-width: calc(100% - 20px);
    flex: 0 0 calc(100% - 20px);
  }

  .add-on-container {
    width: calc(100% - 80px);
  }

  .scroll-button {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}