@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.courseContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  max-width: 1200px;
  margin: 0 auto;
}

.courseCard {
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  animation: fadeIn 0.5s ease-out;
}

.courseCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.bannerSection {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f5fafe;
  transition: background-color 0.3s ease;
}

.bannerImage {
  max-width: 32%;
  object-fit: cover;
  border-radius: 12px;
  margin-right: 20px;
  transition: transform 0.3s ease;
}

.bannerImage:hover {
  transform: scale(1.05);
}

.bannerContent {
  flex-grow: 1;
}

.bannerContent h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
  transition: color 0.3s ease;
}

.courseDetails {
  padding: 0px;
  margin-top: 12px;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  color: #666;
  margin-bottom: 10px;
}

.paymentStatus {
  color: #4caf50;
  font-weight: 700;
}

.progressText {
  font-size: 16px;
  color: #333;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: left;
}

.progressSteps {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progressStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
}

.progressStep:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;
  z-index: 1;
  transition: background-color 0.3s ease;
}

.stepDot {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  z-index: 2;
  position: relative;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepInnerDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e0e0e0;
  transition: all 0.3s ease;
}

.stepLabel {
  font-size: 12px;
  color: #666;
  margin-top: 8px;
  transition: color 0.3s ease;
}

.progressStep.completed .stepDot {
  border-color: #4caf50;
}

.progressStep.completed .stepInnerDot {
  background-color: #4caf50;
}

.progressStep.completed::after {
  background-color: #4caf50;
}

.progressStep.upcoming .stepDot {
  border-color: #ff9800;
}

.progressStep.upcoming .stepInnerDot {
  background-color: #ff9800;
}

.progressStep.pending .stepDot {
  border-color: #e0e0e0;
}

.progressStep.pending .stepInnerDot {
  background-color: #e0e0e0;
}

.actionButtons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.actionButton {
  display: flex;
  align-items: center;
  background-color: #f0f4f8;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
}

.actionButton:hover {
  background-color: #4f6cbb;
  color: #ffffff;
  transform: translateY(-2px);
}

.actionIcon {
  margin-right: 8px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .actionButton {
    padding: 10px 75px;
  }

  .progressStep {
    align-items: start;
  }

  .bannerSection {
    flex-direction: column;
    align-items: flex-start;
  }

  .bannerImage {
    margin-bottom: 15px;
    max-width: 100%;
  }

  .detailRow {
    flex-direction: column;
  }

  .detailRow span {
    margin-bottom: 8px;
  }

  .actionButtons {
    flex-direction: column;
  }

  .actionButton {
    width: 100%;
    justify-content: center;
  }

  .progressSteps {
    flex-wrap: wrap;
  }

  .progressStep {
    flex-basis: 50%;
    margin-bottom: 15px;
  }

  .progressStep:not(:last-child)::after {
    display: none;
  }
}

@media (max-width: 480px) {
  .bannerContent h2 {
    font-size: 20px;
  }

  .progressStep {
    flex-basis: 100%;
  }
}