.address {
  margin-top: 20px;
}

.address h2 {
  font-size: 18px;
  margin-bottom: 15px;
  /* margin-top: 90px; */
}

.address-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.address-info {
  display: flex;
  align-items: flex-start;
}

.location-icon {
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: 15px;
}

.name {
  font-weight: bold;
  margin-bottom: 5px;
}

.change-button {
  background-color: transparent;
  color: #4267B2;
  border: 1px solid #4267B2;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 576px) {
  .address-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .change-button {
    margin-top: 10px;
    width: 100%;
  }
}