.oneContainerDiv {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.OneFaq {
  margin-top: 16px;
  transition: margin 0.3s ease;
  margin-top: 10px;
}

.OneFaq:last-child {
  border-bottom: none;
}

.OneFaqQuestion {
  background-color: #ffffff;
  color: #2f2f2f;
  padding: 18px 20px;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-wrap: nowrap;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, padding 0.5s ease-in-out;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.OneFaqQuestion:hover {
  background-color: #eff4ff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.OneFaqQuestion svg {
  transition: transform 0.3s ease;
}

.OneFaqQuestion.active svg {
  transform: rotate(180deg);
}

.OneFaqAnswer {
  /* padding: 30px; */
  background-color: #f8f8f8;
  border-top: 1px solid #ddd;
  border-radius: 8px 8px 8px 8px;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .OneFaqQuestion {
    padding: 15px;
  }

  .OneFaqAnswer.active {
    padding: 15px;
  }
}

.oneContentdiv,
.oneCategorydiv {
  background-color: #ffffff;
  color: #2f2f2f;
  padding: 18px 20px;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.oneContentdiv:hover,
.oneCategorydiv:hover {
  background-color: #eff4ff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.oneContent {
  margin-top: 16px;
  transition: margin 0.3s ease;
  margin-top: 10px;
}

.oneContent:last-child {
  border-bottom: none;
}

.oneContentImage {
  width: 60px;
  border-radius: 4px;
}

.oneContentText {
  font-size: 16px;
}

.oneContentSubText {
  font-size: 12px;
}

.backIconDiv {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.spinnerIcon {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.OneFaqAnswer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-out;
}

.OneFaqAnswer.open {
  max-height: 10000px;
  transition: max-height 0.6s ease-in;
}

.OneFaqAnswerContent {
  padding: 20px;
}

.iconRotate {
  transition: transform 0.6s ease;
}

/* Download App Popup */
.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.popupContent {
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  max-width: 470px;
  width: 90%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  transform: translateY(20px);
  opacity: 0;
  animation: slideIn 0.3s ease-out 0.1s forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.closeIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #3e67be;
  transition: color 0.5s;
}

.closeIcon:hover {
  color: #526aa0;
}

.popupContent h2 {
  margin-top: 0;
  color: #333;
  font-size: 1.5rem;
}

.popupContent p {
  color: #666;
  margin-bottom: 20px;
}

.downloadButtons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.downloadButtons button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: transform 0.2s, box-shadow 0.2s;
}

.downloadButtons button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.downloadButtons button svg {
  margin-right: 8px;
  font-size: 1.2rem;
}

span.WindowsText {
  color: #2f2f2f;
}

span.TextAndroid {
  color: #2f2f2f;
}

.androidButton {
  background-color: #ffffff;
  color: #3DDC84;
  border: 1px solid #000000;
}

.iosButton {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #000000;
}

.windowsButton {
  background-color: #ffffff;
  color: #0078D7;
  border: 1px solid #000000;
}

@media (max-width: 480px) {
  .downloadButtons {
    flex-direction: column;
  }

  .downloadButtons button {
    width: 100%;
  }
}